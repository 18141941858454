import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AuthSelectors } from "src/app/auth/store";
import { AppSelectors } from "src/app/store";
import { PartnerShiftsState, partnerShiftsFeatureKey } from "./partner-shifts.reducer";

export const selectPartnerShiftsState = createFeatureSelector<PartnerShiftsState>(
  partnerShiftsFeatureKey
);

export const selectPartnerCompanyId = createSelector(selectPartnerShiftsState, state => state.partnerCompanyId);
export const selectTableVM = createSelector(selectPartnerShiftsState, state => state.tableVM);
export const selectPagination = createSelector(selectPartnerShiftsState, state => state.pagination);
export const selectIsDataSourceLoading = createSelector(selectPartnerShiftsState, state => state.isDataSourceLoading);
export const selectPaginatorLength = createSelector(selectPartnerShiftsState, state => state.totalRowsCount);
export const selectPageIndex = createSelector(selectPartnerShiftsState, state => state.pagination.pageIndex);
export const selectPageSize = createSelector(selectPartnerShiftsState, state => state.pagination.pageSize);
export const selectPartnerCompanyAggregate = createSelector(AuthSelectors.selectPartnerCompanyAggregates, selectPartnerCompanyId,
  (partnerCompanyAggregates, companyId) => partnerCompanyAggregates?.find(pc => pc.id === companyId)
);
export const selectPartnerCompanyArea = createSelector(AppSelectors.selectAreas, selectPartnerCompanyAggregate,
  (areas, partnerCompany) => areas?.find(area => area.id === partnerCompany?.company?.area_id)
);
export const selectDriverAggregates = createSelector(selectPartnerShiftsState, state => state.driverAggregates);
export const selectDateRange = createSelector(selectPartnerShiftsState, state => state.dateFilter);
export const selectDriverFilterOptions = createSelector(selectDriverAggregates, driverAggregates =>
  driverAggregates
    ? driverAggregates.map(agg => ({
      value: agg.id,
      display: agg.driver.first_name + ' ' + agg.driver.last_name
    })).sort((a, b) => a.display.localeCompare(b.display))
    : []
);
export const selectDriverFilterValues = createSelector(selectPartnerShiftsState, state => state.driverFilterValues);
export const selectDataSourceParameters = createSelector(selectPagination, selectDateRange, selectDriverFilterValues, (pagination, dateRange, driverFilterValues) =>
({
  startDate: dateRange.startDate,
  endDate: dateRange.endDate,
  driverIds: driverFilterValues,
  pageSize: pagination.pageSize,
  pageIndex: pagination.pageIndex,
  previousPageIndex: pagination.previousPageIndex
})
);
