import { createReducer, on } from "@ngrx/store";
import { DriverAggregate } from "src/app/core/models/firestore.model";
import { ShiftTable } from "src/app/shifts/models/shift-table";
import { PartnerShiftActions } from ".";

export const partnerShiftsFeatureKey = 'partnerShifts';

export interface PartnerShiftsState {
  dateFilter: {
    startDate: string | null;
    endDate: string | null;
  };
  tableVM: ShiftTable | null;
  driverAggregates: DriverAggregate[] | undefined;
  driverFilterValues: string[];
  partnerCompanyId: string | null;
  totalRowsCount: number;
  pagination: {
    pageSize: number;
    pageIndex: number;
    previousPageIndex: number | undefined;
  };
  isDataSourceLoading: boolean;

}

export const initialState: PartnerShiftsState = {
  dateFilter: { startDate: null, endDate: null },
  tableVM: {
    rows: null,
    footer: null
  },
  driverFilterValues: [],
  driverAggregates: undefined,
  isDataSourceLoading: false,
  partnerCompanyId: null,
  totalRowsCount: 0,
  pagination: {
    pageSize: 25,
    pageIndex: 0,
    previousPageIndex: undefined
  }
};

export const partnerShiftsReducer = createReducer(
  initialState,
  on(PartnerShiftActions.shiftsInit, (state, { partnerCompanyId }): PartnerShiftsState => ({
    ...initialState,
    partnerCompanyId
  })),

  on(PartnerShiftActions.dataChanged, (state, action): PartnerShiftsState => ({
    ...state,
    tableVM: action.tableVM,
    isDataSourceLoading: false,
    totalRowsCount: action.totalCount
  })),
  on(PartnerShiftActions.driverAggregatesChanged, (state, action): PartnerShiftsState => ({
    ...state,
    driverAggregates: action.driverAggregates
  })),
  on(PartnerShiftActions.shiftsDateRangeChanged, (state, action): PartnerShiftsState => ({
    ...state,
    dateFilter: {
      startDate: action.startDate,
      endDate: action.endDate
    },
    pagination: {
      ...state.pagination,
      pageIndex: 0,
      previousPageIndex: undefined
    },
    isDataSourceLoading: true
  })),
  on(PartnerShiftActions.driversFilterChanged, (state, action): PartnerShiftsState => ({
    ...state,
    driverFilterValues: action.driverIds,
    pagination: {
      ...state.pagination,
      pageIndex: 0,
      previousPageIndex: undefined
    },
    isDataSourceLoading: true
  })),
  on(PartnerShiftActions.pageEvent, (state, action): PartnerShiftsState => ({
    ...state,
    pagination: {
      pageSize: action.pageSize,
      pageIndex: action.pageIndex,
      previousPageIndex: action.previousPageIndex
    },
    isDataSourceLoading: true
  })),
);