import { createAction, props } from "@ngrx/store";
import { DriverAggregate } from "src/app/core/models/firestore.model";
import { ShiftTable } from "src/app/shifts/models/shift-table";

export const shiftsInit = createAction(
  '[PartnerShifts] Shifts init',
  props<{ partnerCompanyId: string; }>()
);
export const shiftsDestroy = createAction(
  '[PartnerShifts] Shifts destroy'
);

export const dataChanged = createAction(
  '[PartnerShifts] Data changed',
  props<{ tableVM: ShiftTable, totalCount: number }>()
);
export const pageEvent = createAction(
  '[PartnerShifts] Page event',
  props<{ pageSize: number; pageIndex: number; previousPageIndex?: number }>()
);

export const driverAggregatesChanged = createAction(
  '[PartnerShifts] Driver aggregates changed',
  props<{ driverAggregates: DriverAggregate[]; }>()
);

export const shiftsDateRangeChanged = createAction(
  '[PartnerShifts] Shifts date range changed',
  props<{ startDate: string | null; endDate: string | null; }>()
);

export const driversFilterChanged = createAction(
  '[Dashboard] Driver filter changed',
  props<{ driverIds: string[]; }>()
);

export const resetPagination = createAction(
  '[PartnerShifts] Reset pagination'
);

export const downloadCsvClicked = createAction(
  '[PartnerShifts] Download csv clicked'
);

export const downloadSuccess = createAction(
  '[PartnerShifts] Download success'
);

export const downloadFailed = createAction(
  '[PartnerShifts] Download failed'
);